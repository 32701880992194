var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"MERCH addGood"},[(_vm.AuditbusinessData.basicInfo)?_c('div',{staticClass:"merch-card"},[_c('div',{staticClass:"message-box scorr-roll"},[(_vm.AuditbusinessData.basicInfo)?[(
            _vm.AuditbusinessData.auditInfo.status == 2 ||
            _vm.AuditbusinessData.auditInfo.status == 3
          )?_c('div',[_c('NotPassGood',{attrs:{"notData":_vm.AuditbusinessData}})],1):_vm._e()]:_vm._e(),_c('div',[_c('MerchantGood',{attrs:{"businessInfo":_vm.goodsMessage.businessInfo,"businessInfoNew":_vm.AuditbusinessData.business}})],1),_c('div',[_c('BasicMessageGood',{attrs:{"basicInfo":_vm.goodsRedact.basicInfo,"picture":_vm.goodsMessage.picture,"manufacturerInfo":_vm.goodsMessage.manufacturerInfo,"label":_vm.goodsMessage.label,"basicinfoData":_vm.AuditbusinessData.basicInfo,"imgDate":_vm.AuditbusinessData.picture,"manuData":_vm.AuditbusinessData.manufacturerInfo,"labData":_vm.AuditbusinessData.label}})],1),_c('div',[(_vm.AuditbusinessData.instruction.contentType==1)?_c('ExplainGood',{attrs:{"instruction":_vm.goodsMessage.instruction,"instDate":_vm.AuditbusinessData.instruction.content}}):_vm._e(),(_vm.AuditbusinessData.instruction.contentType==2)?_c('div',{staticClass:"update"},[_c('div',{staticClass:"title"},[_vm._v("商品说明书")]),_c('div',{domProps:{"innerHTML":_vm._s(_vm.AuditbusinessData.instruction.contentext)}})]):_vm._e()],1),_c('div',[_c('PriceGood',{attrs:{"priceAndInventory":_vm.goodsMessage.priceAndInventory,"priceData":_vm.AuditbusinessData.priceAndInventory}})],1),_c('div',[_c('SellSetGood',{attrs:{"saleSetting":_vm.goodsMessage.saleSetting,"saleData":_vm.AuditbusinessData.saleSetting}})],1),_c('div',[_c('QualificationGood',{attrs:{"qualify":_vm.goodsMessage.qualify,"qualifyData":_vm.AuditbusinessData.qualify}})],1)],2),_c('div',{staticClass:"btn-box"},[_c('div',{staticClass:"go-back",on:{"click":_vm.toBack}},[_vm._v("返回")]),(
          _vm.AuditbusinessData.auditInfo.status != 2 &&
          _vm.AuditbusinessData.auditInfo.status != 3
        )?_c('div',{staticClass:"refuse",on:{"click":_vm.refuseMessage}},[_vm._v(" 审核驳回 ")]):_vm._e(),(
          _vm.AuditbusinessData.auditInfo.status != 2 &&
          _vm.AuditbusinessData.auditInfo.status != 3
        )?_c('div',{staticClass:"pass",on:{"click":_vm.passMessage}},[_vm._v(" 审核通过 ")]):_vm._e()]),_c('div',[_c('el-dialog',{attrs:{"title":"审核驳回提醒","visible":_vm.isRefuse,"width":"400px"},on:{"update:visible":function($event){_vm.isRefuse=$event}}},[_c('div',{staticClass:"explain"},[_vm._v(" 确定驳回当前【新建商品】审核？如果当前商品资质或信息不符合平台要求，请在驳回原因中注明 ")]),_c('div',{staticClass:"reason"},[_c('span',[_vm._v("驳回原因：")]),_c('el-input',{attrs:{"type":"textarea","rows":2,"maxlength":"200","show-word-limit":"","placeholder":"请输入内容"},model:{value:(_vm.AuditMessage.remark),callback:function ($$v) {_vm.$set(_vm.AuditMessage, "remark", $$v)},expression:"AuditMessage.remark"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.isRefuse = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.notarizeRefuse}},[_vm._v("驳 回")])],1)])],1),_c('div',[_c('el-dialog',{attrs:{"title":"审核通过提醒","visible":_vm.ispass,"width":"400px"},on:{"update:visible":function($event){_vm.ispass=$event}}},[_c('div',{staticClass:"explain"},[_vm._v(" 确定通过当前【新建商品】审核？请您仔细核对商品信息，以保证当前商品资质信息均符合要求且填写无误。 ")]),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":function($event){_vm.ispass = false}}},[_vm._v("取 消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.nextPass}},[_vm._v("审核通过")])],1)])],1)]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }