<template>
  <div class="MERCH addGood">
    <div class="merch-card" v-if="AuditbusinessData.basicInfo">
      <div class="message-box scorr-roll">
        <!-- 审核时间线 -->
        <!-- <div class="time-wire"></div> -->
        <!-- 未通过显示框 -->
        <template v-if="AuditbusinessData.basicInfo">
          <div
            v-if="
              AuditbusinessData.auditInfo.status == 2 ||
              AuditbusinessData.auditInfo.status == 3
            "
          >
            <NotPassGood :notData="AuditbusinessData" />
          </div>
        </template>

        <!-- 供应商 -->
        <div>
          <MerchantGood
            :businessInfo="goodsMessage.businessInfo"
            :businessInfoNew="AuditbusinessData.business"
          />
        </div>
        <!-- 基本信息 -->
        <div>
          <BasicMessageGood
            :basicInfo="goodsRedact.basicInfo"
            :picture="goodsMessage.picture"
            :manufacturerInfo="goodsMessage.manufacturerInfo"
            :label="goodsMessage.label"
            :basicinfoData="AuditbusinessData.basicInfo"
            :imgDate="AuditbusinessData.picture"
            :manuData="AuditbusinessData.manufacturerInfo"
            :labData="AuditbusinessData.label"
          />
        </div>
        <!-- 商品说明书 -->
        <div>
          <ExplainGood
          v-if="AuditbusinessData.instruction.contentType==1"
            :instruction="goodsMessage.instruction"
            :instDate="AuditbusinessData.instruction.content"
          />
          <div v-if="AuditbusinessData.instruction.contentType==2" class="update">
            <div class="title">商品说明书</div>
            <div v-html="AuditbusinessData.instruction.contentext"></div>
          </div>
        </div>
        <!--   -->
        <!-- 价格/库存 -->
        <div>
          <PriceGood
            :priceAndInventory="goodsMessage.priceAndInventory"
            :priceData="AuditbusinessData.priceAndInventory"
          />
        </div>
        <!-- 销售设置 -->
        <div>
          <SellSetGood
            :saleSetting="goodsMessage.saleSetting"
            :saleData="AuditbusinessData.saleSetting"
          />
        </div>
        <!-- 产品资质 -->
        <div>
          <QualificationGood
            :qualify="goodsMessage.qualify"
            :qualifyData="AuditbusinessData.qualify"
          />
        </div>
      </div>
      <div class="btn-box">
        <div class="go-back" @click="toBack">返回</div>
        <div
          class="refuse"
          @click="refuseMessage"
          v-if="
            AuditbusinessData.auditInfo.status != 2 &&
            AuditbusinessData.auditInfo.status != 3
          "
        >
          审核驳回
        </div>
        <div
          class="pass"
          @click="passMessage"
          v-if="
            AuditbusinessData.auditInfo.status != 2 &&
            AuditbusinessData.auditInfo.status != 3
          "
        >
          审核通过
        </div>
        <!-- <div class="refuse" @click="toBack">返回</div>
        <div class="refuse" @click="refuseMessage">拒绝</div>
        <div class="pass" @click="passMessage">通过</div> -->
      </div>
      <!-- <div class="btn-box" v-else>
        <div class="go-back" @click="toBack">返回</div>
      </div> -->
      <!-- 弹框 -->
      <div>
        <el-dialog title="审核驳回提醒" :visible.sync="isRefuse" width="400px">
          <div class="explain">
              确定驳回当前【新建商品】审核？如果当前商品资质或信息不符合平台要求，请在驳回原因中注明
            </div>
            <div class="reason">
              <span>驳回原因：</span>

              <el-input
                type="textarea"
                :rows="2"
                maxlength="200"
                show-word-limit
                placeholder="请输入内容"
                v-model="AuditMessage.remark"
              >
              </el-input>
            </div>
          <!-- <div>
            <el-input
              type="textarea"
              :rows="2"
              placeholder="请输入内容"
              maxlength="200"
              v-model="AuditMessage.remark"
              show-word-limit
            >
            </el-input>
          </div> -->
          <span slot="footer" class="dialog-footer">
            <el-button @click="isRefuse = false">取 消</el-button>
            <el-button type="primary" @click="notarizeRefuse">驳 回</el-button>
          </span>
        </el-dialog>
      </div>
      <div>
        <el-dialog title="审核通过提醒" :visible.sync="ispass" width="400px">
          
          <div class="explain">
            确定通过当前【新建商品】审核？请您仔细核对商品信息，以保证当前商品资质信息均符合要求且填写无误。
            </div>
          <span slot="footer" class="dialog-footer">
            <el-button @click="ispass = false">取 消</el-button>
            <el-button type="primary" @click="nextPass">审核通过</el-button>
          </span>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import MerchantGood from "@/components/ordinaryGoods/merchant.vue"; //供应商
import BasicMessageGood from "@/components/ordinaryGoods/basicMessage.vue"; //基本信息
import ExplainGood from "@/components/ordinaryGoods/explain.vue"; //商品说明书
import SellSetGood from "@/components/ordinaryGoods/sellSet.vue"; //销售设置
import PriceGood from "@/components/ordinaryGoods/price.vue"; //价格/库存
import QualificationGood from "@/components/ordinaryGoods/qualification.vue"; //产品资质
import NotPassGood from "../../audit/merchAudit/merchMessage/comp/notPass.vue"; //产品资质
import { createNamespacedHelpers } from "vuex";
const { mapState, mapActions } = createNamespacedHelpers("Goods"); //vuex公共库
export default {
  data() {
    return {
      activeName: 0,
      // 引入详情json
      goodsMessage: require("../../../assets/json/goodsAntd.json"),
      goodsRedact: require("@/assets/json/goodRedact.json"),
      AuditMessage: {
        id: this.$route.query.id,
        remark: "",
        status: 2,
        supertubeId:''
      },
      isRefuse: false, //孔氏拒绝弹框
      ispass: false, //通过拒绝类型
    };
  },
  components: {
    MerchantGood, //供应商
    BasicMessageGood, //基本信息
    ExplainGood, //商品说明书
    SellSetGood, //销售设置
    PriceGood, //价格/库存
    QualificationGood, //产品资质
    NotPassGood, //未通过
  },
  computed: {
    ...mapState(["AuditbusinessData"]),
  },
  created() {
    if (this.$route.query.id) {
      this.postgoodsAuditbusinessDetail({ id: this.$route.query.id });
    }
  },
  methods: {
    ...mapActions(["postgoodsAuditbusinessDetail", "postgoodsAuditaudit",'postgoodsAudit']),
    //  返回
    toBack() {
      this.$router.push({ path: "/goodsCheck" });
    },
    // 拒绝弹框
    refuseMessage() {
      this.isRefuse = true;
    },
    // 通过弹框
    passMessage() {
      this.ispass = true;
    },
    // 通过
    async nextPass() {
      this.AuditMessage = {
        id: this.$route.query.id,
        status: 2,
        supplierId:sessionStorage.getItem('admin_id'),
        platformGoodsInfoId:this.$route.query.platformGoodsInfoId,
      };
      let data = await this.postgoodsAudit(this.AuditMessage);
      if (data.code == 200) {
        this.ispass = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 确认拒绝
    async notarizeRefuse() {
      this.AuditMessage.status = 3;
      if(!this.AuditMessage.remark||this.AuditMessage==''){
        this.$message({
          message: '您还没有填写驳回原因，请填写后重试。',
          type: "warning",
        });
        return 
      }
      this.AuditMessage.platformGoodsInfoId=this.$route.query.platformGoodsInfoId
      let data = await this.postgoodsAudit(this.AuditMessage);
      if (data.code == '000000') {
        this.isRefuse = false;
        this.$router.push({ path: "/goodsCheck" });
        this.$message({
          message: data.message,
          type: "success",
        });
      } else {
        this.$message({
          message: data.message,
          type: "warning",
        });
      }
    },
    // 供应商审核
    goodAudit() {
      this.AuditMessage = {
        id: this.$route.query.id,
      };
    },
    handleCurrentChange() {},
    toSearch() {},
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    //
    toMessage() {
      this.$router.push({ path: "/cloudGoodMessage" });
    },
  },
};
</script>
<style lang="scss" scoped>

.dio-input {
  .el-input {
    // width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    .message-box {
      width: 100%;
      max-height: 75vh;
      padding-top: 10px;
      .time-wire {
        width: 100%;
        height: 100px;
        background: rebeccapurple;
      }
    }
  }
  .btn-box {
    margin-top: 1%;
    width: 100%;
    height: 32px;
    display: flex;
    align-content: center;
    justify-content: center;
    div {
      font-size: 14px;
      text-align: center;
      border-radius: 4px;
      font-weight: normal;
      line-height: 32px;
      // cursor: pointer;
    }
    .go-back {
      width: 88px;
      color: rgba($color: #1c1f23, $alpha: 0.6);
      border: 1px solid #d4d7d9;
    }
    .refuse {
      width: 116px;
      background: #f6b142;
      color: #fff;
      margin: 0 20px;
    }
    .pass {
      width: 116px;
      background: #00bfbf;
      color: #ffffff;
    }
  }
}
.explain{
  line-height: 24px;
}
.reason {
  display: flex;
  line-height: 24px;
  margin-top: 10px;
  span {
    width: 100px ;
  }
}
::v-deep .el-dialog__body{
  padding: 20px;
  padding-top: 0;
}
.update {
  // border-bottom: dashed 1px #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
  }
  .title {
    width: 100%;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: dashed 1px #ddd;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .title::before {
    width: 4px;
    height: 16px;
    background-color: #06b7ae;
    border-radius: 10px;
    display: inline-block;
    content: "";
    margin-right: 10px;
    margin-bottom: -2px;
  }
</style>
